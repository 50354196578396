//Header Font
@font-face {
    font-family: 'bebasNeue';
    src: url('./fonts/bebas-neue/bebasneue_thin.woff2') format('woff2'), url('./fonts/bebas-neue/bebasneue_thin.woff') format('woff');
    font-weight: 200;
    font-style: normal; }

@font-face {
    font-family: 'bebasNeue';
    src: url('./fonts/bebas-neue/bebasneue_light.woff2') format('woff2'), url('./fonts/bebas-neue/bebasneue_light.woff') format('woff');
    font-weight: 300;
    font-style: normal; }

@font-face {
    font-family: 'bebasNeue';
    src: url('./fonts/bebas-neue/bebasneue_book.woff2') format('woff2'), url('./fonts/bebas-neue/bebasneue_book.woff') format('woff');
    font-weight: 400;
    font-style: normal; }

@font-face {
    font-family: 'bebasNeue';
    src: url('./fonts/bebas-neue/bebasneue_regular.woff2') format('woff2'), url('./fonts/bebas-neue/bebasneue_regular.woff') format('woff');
    font-weight: 500;
    font-style: normal; }

@font-face {
    font-family: 'bebasNeue';
    src: url('./fonts/bebas-neue/bebasneue_bold.woff2') format('woff2'), url('./fonts/bebas-neue/bebasneue_bold.woff') format('woff');
    font-weight: 600;
    font-style: normal; }

//body Font
@font-face {
    font-family: 'OpenSans';
    font-weight: 200;
    font-style: normal;
    src: url('./fonts/openSans/OpenSans-Light.eot');
    src: url('./fonts/openSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Light.woff') format('woff'), url('./fonts/openSans/OpenSans-Light.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Light.svg#open_sanslight') format('svg'); }

@font-face {
    font-family: 'OpenSans';
    font-weight: 300;
    font-style: normal;
    src: url('./fonts/openSans/OpenSans-Light.eot');
    src: url('./fonts/openSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Light.woff') format('woff'), url('./fonts/openSans/OpenSans-Light.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Light.svg#open_sanslight') format('svg'); }

@font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    font-style: normal;
    src: url('./fonts/openSans/OpenSans-Regular.eot');
    src: url('./fonts/openSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Regular.woff') format('woff'), url('./fonts/openSans/OpenSans-Regular.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Regular.svg#open_sansregular') format('svg'); }

@font-face {
    font-family: 'OpenSans';
    font-weight: 500;
    font-style: normal;
    src: url('./fonts/openSans/OpenSans-Semibold.eot');
    src: url('./fonts/openSans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Semibold.woff') format('woff'), url('./fonts/openSans/OpenSans-Semibold.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Semibold.svg#open_sanssemibold') format('svg'); }

@font-face {
    font-family: 'OpenSans';
    font-weight: 600;
    font-style: normal;
    src: url('./fonts/openSans/OpenSans-Bold.eot');
    src: url('./fonts/openSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Bold.woff') format('woff'), url('./fonts/openSans/OpenSans-Bold.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Bold.svg#open_sansbold') format('svg'); }

@font-face {
    font-family: 'OpenSans';
    font-weight: 700;
    font-style: normal;
    src: url('./fonts/openSans/OpenSans-Bold.eot');
    src: url('./fonts/openSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Bold.woff') format('woff'), url('./fonts/openSans/OpenSans-Bold.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Bold.svg#open_sansbold') format('svg'); }

@font-face {
    font-family: 'OpenSans';
    font-weight: 800;
    font-style: normal;
    src: url('./fonts/openSans/OpenSans-Bold.eot');
    src: url('./fonts/openSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Bold.woff') format('woff'), url('./fonts/openSans/OpenSans-Bold.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Bold.svg#open_sansbold') format('svg'); }
