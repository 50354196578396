//## Colors
$white: #fff;
$black: #1B1C1A;
$light-gray: lightgray;

//Primary Colors Driven by the bots
$red: #EE243E;
$blue: #21478B;
$yellow: #F2C82D;
$orange: #D34D2B;
$green: #6CB644;
$grayBlue: #ACBBC6;
$lightGray: #D8D8D2;
$darkGray: #363636;
$darkHr: #666666;
$gold: rgb(216, 198, 126);
$gold-text: #d8c67e;
$darkGold: #c8a253;
$bodyLightBorderColor: #b3b3b3;
$darkOffset: #333333;
$borderGrayColor: #ebebeb;

/* Custom element colors */

// Modal Color
$outerBoxBgColor: rgba(255, 255, 255, 0.61);
$innerBoxBgColor: rgba(0, 0, 0, 0.85);

/* Game info page */

// General
$bodyGrayColor: #565656;

// Game section
$phraseTextColor: #5b696c;
$devicesTextColor: #898989;
$devicesBaseTextColor: #292929;

// Game manual
$game-manual-bg: #eeeeef;
$scrollDownColor: $white;

// Slick carousel
$gameInfoCarouselArrowColor: #e5e5e5;
$gameInfoCarouselActiveDotColor: #4d94a1;

// Video Section
$gameInfoVideoSectionBgColor: #e5e5e5;
$text-color: #ffffff;
$invert-text-color: #000000;
$accent-color: #dbbd68;


//## Fonts
$BebasNeue: bebasNeue, sans-serif;
$OpenSans: OpenSans, sans-serif;
$robotoCondensed: 'Roboto Condensed', san-serif;


$font-size-base: 16px !default;
$font-size-large: ceil($font-size-base * 1.25) !default;
$font-size-small: ceil($font-size-base * 0.85) !default;

@mixin font-base {
    font-family: $robotoCondensed;
    -webkit-font-smoothing: antialiased; }

@mixin title-font-base {
    font-family: $robotoCondensed;
    font-size: 2em;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 0.9em;
    .highlight {
        display: inline-block;
        line-height: 0.9em; } }

@mixin btn {
    position: relative;
    display: inline-block;
    font-family: $BebasNeue;
    font-weight: 600;
    font-size: 1.5em;
    padding: 0.5em 0;
    color: $white;
    text-align: center;
    letter-spacing: 1px;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    width: 10em;
    @include respond-max-width(sm) {
        margin-top: 0.5em; }
    span {
        display: inline-block;
        margin-top: 4px;
        position: relative; }
    &.block {
        display: block;
        &.center {
            margin: 0 auto; } } }

@mixin btn-custom {
    padding: 20px 75px;
    text-transform: uppercase;
    font-family: $robotoCondensed;
    font-weight: 700;
    font-size: 1.5em;
    color: #fff;
    background-color: $gold;
    border: 0;
    border-radius: 0;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    cursor: pointer;
    &:hover, &:active, &:focus {
        color: $darkGray; } }


@mixin previous-arrow {
  font-family: 'icomoon';
  content: "\e903"; }

@mixin next-arrow {
  font-family: 'icomoon';
  content: "\e902"; }

@import '~epic-react-common/src/sass/mixins';
