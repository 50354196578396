@import 'variables-mixins.sass';

html, body {
    width: 100%;
    height: 100%; }

body {
    background-color: $white;
    font-family: $OpenSans;
    font-weight: 400;
    font-size: $font-size-base;
    line-height: 1.42857143;
    .infinityblade-wrapper {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $BebasNeue;
            font-weight: 600;
            text-transform: uppercase; }
        h1 {
            font-size: 5em; }
        h2 {
            font-size: 3em; }
        h3 {
            font-size: 1.25em; }
        p {
            font-size: 1em;
            @include respond-max-width(xs) {
                font-size: 0.85em; } } }
    .btn, .btn-primary {
        @include btn; }

    #epicEuCookie {
        color: $white;
        opacity: 0.9;
        a, a:hover, a:focus, a:active {
            color: yellow; } }

    #epicEuCookieClose.eu-cookie-close {
        @include btn;
        background: transparent;
        border: none;
        padding: 0.5em 1.75em;
        margin: 0.5em auto;
        display: block;
        &:hover {
            background: transparent; }
        &::before {
            z-index: -1; } } }

.ellipsis {
    @include ellipsis; }
//parallax css
.parallax-scene {
    margin: 0;
    padding: 0; }

.table-cell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%; }

.pull-right {
    float: right; }

.pull-left {
    float: left; }

.no-pointer-events {
    pointer-events: none; }

.hidden {
    display: none !important; }

.not-visible {
    visibility: hidden; }

.infinityblade-site-block {
    padding: 2em;
    color: $black;
    background-color: $white;
    border: 1px solid $lightGray;
    max-width: 800px;
    margin: 1em auto;
    text-align: left;
    .site-block-header {
        padding-bottom: 1em;
        border-bottom: 1px solid $lightGray;
        margin-bottom: 2em; } }
