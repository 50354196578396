@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icons/icomoon.eot?ig5xjd');
    src:  url('fonts/icons/icomoon.eot?ig5xjd#iefix') format('embedded-opentype'), url('fonts/icons/icomoon.ttf?ig5xjd') format('truetype'), url('fonts/icons/icomoon.woff?ig5xjd') format('woff'), url('fonts/icons/icomoon.svg?ig5xjd#icomoon') format('svg');
    font-weight: normal;
    font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }


.icon-youtube:before {
  content: "\e908"; }

.icon-twitch:before {
  content: "\e909"; }

.icon-twitter:before {
  content: "\ea96"; }

.icon-instagram:before {
  content: "\ea92"; }

.icon-cross:before {
  content: "\ea0f"; }

.icon-facebook:before {
  content: "\e900"; }

.icon-discord:before {
  content: "\e901"; }

.icon-chevron-right:before {
  content: "\e902"; }

.icon-chevron-left:before {
  content: "\e903"; }

.icon-chevron-down:before {
  content: "\e904"; }

.icon-chevron-up:before {
  content: "\e905"; }

.icon-plus-square:before {
  content: "\e906"; }

.icon-minus-square:before {
  content: "\e907"; }


@mixin previous-arrow {
  font-family: 'icomoon';
  content: "\e903"; }

@mixin next-arrow {
  font-family: 'icomoon';
  content: "\e902"; }

